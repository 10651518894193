import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import DropdownActions from './DropdownActions';

export const Backdrop = styled.div`
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 66px;
    left: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.85);
`;

export const DropdownContent = styled.div`
    border: 1px solid #cecece;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: #ffffff;
    z-index: 6;
    position: absolute;
    box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.15);
    top: 100%;
    overflow-y: auto;
    min-width: inherit;
    width: 100%;
    ${breakpoint('md')`
    width: inherit;
  `};
`;

export const DropdownContentWithAnchorRight = styled.div`
    border: 1px solid #cecece;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 10rem;
    background-color: #ffffff;
    z-index: 6;
    position: absolute;
    box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.15);
    top: 100%;
    overflow-y: auto;
    min-width: inherit;
    width: ${(props) => (props.customWidth ? 'inherit' : '100%')};
    right: 0;
`;

export const Wrapper = styled.div`
    position: relative;
    margin-bottom: 1rem;
    height: 50px;
`;

class Dropdown extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            value: this.props.value,
        };
        this.mounted = true;
        this.anchorRight = props.anchorRight;

        this.handleApply = this.handleApply.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick, false);
        document.addEventListener('touchend', this.handleDocumentClick, false);
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('click', this.handleDocumentClick, false);
        document.removeEventListener('touchend', this.handleDocumentClick, false);
    }

    menu;
    mounted;
    anchorRight;

    handleMouseDown = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
        const { trackOpen } = this.props;
        if (this.state.isOpen === false && trackOpen) {
            trackOpen();
        }
        this.focus();
    };

    closeSelect = () => {
        this.setState({
            isOpen: false,
        });
    };

    focus = () => {
        if (this.menu) {
            this.menu.focus();
        }
    };

    blur = () => {
        if (this.menu) {
            this.menu.blur();
        }
    };

    handleApply() {
        const { trackApply } = this.props;
        if (trackApply) {
            trackApply();
        }
        this.closeSelect();
    }

    handleClear(shouldClose = true) {
        const { trackClear } = this.props;
        if (trackClear) {
            trackClear();
        }
        if (shouldClose) {
            this.closeSelect();
        }
    }

    handleDocumentClick = (event) => {
        if (this.mounted) {
            if (!ReactDOM.findDOMNode(this).contains(event.target)) {
                if (this.state.isOpen) {
                    this.handleApply();
                    this.closeSelect();
                }
            }
        }
    };

    renderInput() {}

    renderMenu() {}

    renderMenuActions = () => {
        return <DropdownActions handleClear={this.handleClear} handleApply={this.handleApply} />;
    };

    render() {
        const { isOpen } = this.state;
        const { className, provideActions, anchorRight, customWidth } = this.props;
        return (
            <Wrapper className={className}>
                <div onMouseDown={this.handleMouseDown}>{this.renderInput()}</div>
                {isOpen ? (
                    <>
                        {anchorRight ? (
                            <DropdownContentWithAnchorRight customWidth={customWidth}>
                                {this.renderMenu()}
                                {provideActions && this.renderMenuActions()}
                            </DropdownContentWithAnchorRight>
                        ) : (
                            <DropdownContent customWidth={customWidth}>
                                {this.renderMenu()}
                                {provideActions && this.renderMenuActions()}
                            </DropdownContent>
                        )}
                    </>
                ) : null}
            </Wrapper>
        );
    }
}

Dropdown.defaultProps = {
    customWidth: false,
    provideActions: false,
    provideApplyAndCancelActions: true,
    provideClearAction: true,
};

Dropdown.propTypes = {
    t: PropTypes.func,
    trackApply: PropTypes.func,
    trackClear: PropTypes.func,
    trackOpen: PropTypes.func,
    provideActions: PropTypes.bool,
    provideApplyAndCancelActions: PropTypes.bool,
    provideClearAction: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    anchorRight: PropTypes.bool,
    headerHeight: PropTypes.number,
    showArrow: PropTypes.bool,
};

Dropdown.defaultProps = {
    showArrow: true,
};

export default Dropdown;
