import {
    catSitting,
    doggyDayCare,
    dogSitting,
    dogWalking,
    homeDogBoarding,
    homeVisits,
    houseSitting,
    petSitting,
} from '../Pawshake/Core/Service';
import { isClientSide, isProduction } from '../utils/isClientSide';
import { isGALoaded } from '../services/tracking.service';

const trackEventMock = (category, action, eventLabel) => {
    console.log(
        `Category: ${category} | action: ${action} |  eventLabel: ${
            typeof eventLabel === 'undefined' ? action : eventLabel
        }`
    );
};

export const getServiceLabelForTracking = (service) => {
    let serviceLabel = 'Unknown';
    switch (service) {
        case homeDogBoarding:
            serviceLabel = 'Home dog boarding';
            break;
        case doggyDayCare:
            serviceLabel = 'Doggy day care';
            break;
        case dogWalking:
            serviceLabel = 'Dog walking';
            break;
        case homeVisits:
            serviceLabel = 'Home visits';
            break;
        case catSitting:
            serviceLabel = 'Cat sitting';
            break;
        case dogSitting:
            serviceLabel = 'Dog sitting';
            break;
        case houseSitting:
            serviceLabel = 'House sitting';
            break;
        case petSitting:
            serviceLabel = 'Pet sitting';
            break;
    }

    return serviceLabel;
};

export const trackEvent = (category, action, eventLabel) => {
    if (isProduction() && isGALoaded()) {
        window.gtag('event', action, {
            event_category: category,
            event_label: typeof eventLabel === 'undefined' ? action : eventLabel,
            value: 1,
        });
    } else {
        trackEventMock(category, action, eventLabel);
    }
};

export const trackEventV2 = (event) => {
    if (!isClientSide()) {
        return;
    }

    const eventToTrack = {
        event: event.eventName,
        ...event.properties,
    };

    window.dataLayer.push(eventToTrack);

    if (!isProduction()) {
        console.log(eventToTrack);
    }
};

export const trackException = (errorDescription, isFatal = false) => {
    if (isProduction() && isGALoaded()) {
        window.gtag('event', 'exception', {
            description: errorDescription,
            fatal: isFatal,
        });
    } else {
        console.log(`description: ${errorDescription}, isFatal: ${isFatal}`);
    }
};
