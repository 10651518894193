import styled from 'styled-components';

export const Flex = styled.div<{
    flexDirection?: 'row' | 'column';
    justifyContent?: string;
    alignItems?: string;
    padding?: string;
    gap?: string;
}>`
    display: flex;
    flex-direction: ${(props) => props.flexDirection || 'row'};
    gap: ${(props) => props.gap || '0'};
    padding: ${(props) => props.padding || '0'};
    justify-content: ${(props) => props.justifyContent || 'initial'};
    align-items: ${(props) => props.alignItems || 'initial'};
`;
