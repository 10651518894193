import styled from 'styled-components';
import Colors from '../../../styles/colors';

const StyledLabel = styled.label`
    display: block;
    color: ${Colors.text.default};
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
`;

const Label = (props) => {
    return (
        <StyledLabel htmlFor={props.htmlFor} className={props.className} style={props.style}>
            {props.children}
        </StyledLabel>
    );
};

export default Label;
