import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../../i18n';
import Button from '../Button';

const Actions = styled.div`
    padding: 1rem;
`;

const LeftAction = styled.div`
    display: inline-block;
    width: 50%;
`;
const RightAction = styled.div`
    display: inline-block;
    width: 50%;
    text-align: right;
`;

const ClearAction = styled(Button)`
    color: #747474;
`;

const ApplyAction = styled(Button)`
    color: #00c38a;
`;

const DropdownActions = (props) => {
    const { t, handleClear, handleApply } = props;

    return (
        <Actions>
            <LeftAction>
                <ClearAction
                    onClick={(e) => {
                        e.preventDefault();
                        handleClear();
                    }}
                    isLinkButton={true}
                >
                    {t('dropdown.clear')}
                </ClearAction>
            </LeftAction>
            <RightAction>
                <ApplyAction
                    onClick={(e) => {
                        e.preventDefault();
                        handleApply();
                    }}
                    isLinkButton={true}
                >
                    {t('dropdown.apply')}
                </ApplyAction>
            </RightAction>
        </Actions>
    );
};

export default withTranslation('common')(DropdownActions);
