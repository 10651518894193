import { useTranslation } from 'i18n';
import Colors from 'styles/colors';
import { Flex } from './flex.component';

type NewBadgeProps = {
    style?: React.CSSProperties;
};

export const NewBadge = (props: NewBadgeProps) => {
    const { style = {} } = props;
    const { t } = useTranslation('common');
    return (
        <Flex
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '.125rem .5rem',
                borderRadius: '.5rem',
                fontSize: '.875rem',
                lineHeight: '1.25rem',
                fontWeight: 500,
                background: `${Colors.red[500]} linear-gradient(${Colors.red[300]}, ${Colors.red[500]})`,
                color: Colors.text.onDark,
                width: 'fit-content',
                boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px',
                textTransform: 'uppercase',
                ...style,
            }}
        >
            {t('generic.new')}
        </Flex>
    );
};
