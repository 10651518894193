import React, { createContext } from 'react';

export const FeatureFlagContext = createContext({});

export function withFeatureFlags(Component) {
    const featureFlagComponent = function FeatureFlagComponent(props) {
        return (
            <FeatureFlagContext.Consumer>
                {(featureFlags) => <Component {...props} featureFlags={featureFlags} />}
            </FeatureFlagContext.Consumer>
        );
    };

    featureFlagComponent.getInitialProps = Component.getInitialProps;

    return featureFlagComponent;
}
