import { trackEvent } from '../../lib/analytics';

export const trackDashboardEvent = (action) => {
    trackEvent('Dashboard', action);
};

export const trackDashboardPetOwnerAction = (action) => {
    trackDashboardEvent('Pet Owner - ' + action);
};

export const trackDashboardSitterAction = (action) => {
    trackDashboardEvent('Sitter - ' + action);
};

export const trackDashboardSitterAvailabilityAction = (action, eventLabel) => {
    trackEvent('Dashboard - Sitter - Availability', action, eventLabel);
};
