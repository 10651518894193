import { identify, init, shutdown, event, isInitialized } from '@fullstory/browser';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { isClientSide, isProduction, isDevelopment } from '../utils/isClientSide';
import { trackEvent } from '../lib/analytics';

export type AnalyticsData = {
    eventName: string;
    properties: { [key: string]: unknown };
};

type PsJwtPayload = JwtPayload & {
    sub: string; // user id
    cc: string | undefined; // country code
    loc: string | undefined; // locale
    mmpr: boolean | undefined; // meets minimum profile requirements
    is: boolean | undefined; // is sitter
};

type AnalyticsUserData = {
    userId: string;
    language: string | undefined;
    countryCode: string | undefined;
    hasMinimumProfileRequirementsSet: boolean | undefined;
    isSitter: boolean | undefined;
};

declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
    }
}

export const isGALoaded = () => isClientSide() && typeof window.gtag !== 'undefined';

export class TrackingService {
    identifyUserFromToken(jwtToken: string) {
        const decodedToken = jwtDecode<PsJwtPayload>(jwtToken);
        const userId = decodedToken.sub;
        const countryCode = decodedToken.cc;
        const language = decodedToken.loc?.split('-').shift();
        const meetsMinProfileRequirements = decodedToken.mmpr;
        const isSitter = decodedToken.is;

        const userData: AnalyticsUserData = {
            userId: userId,
            language: language,
            countryCode: countryCode,
            hasMinimumProfileRequirementsSet: meetsMinProfileRequirements,
            isSitter: isSitter,
        };

        this.identifyUser(userData);
    }

    trackEventV2(event: AnalyticsData) {
        if (!window) {
            return;
        }

        // No need to call fullstory here, it should be integrated using GTM instead
        const dlEvent = {
            event: event.eventName,
            ...event.properties,
        };
        window.dataLayer.push(dlEvent);

        if (!isProduction()) {
            console.log(dlEvent);
        }
    }

    trackEvent(category: string, name: string, properties = {}) {
        if (isInitialized()) {
            event(category, {
                name: name,
                properties: properties,
            });
        }
        trackEvent(category, name);
    }

    shutdown() {
        if (isInitialized()) {
            shutdown();
        }

        this.identifyUser(undefined);
        this.trackEventV2({
            eventName: 'logout',
            properties: {},
        });
    }

    private shouldEnableFullStory = () => {
        if (isClientSide() === false) {
            return false;
        }

        if (process.env.NEXT_PUBLIC_IS_FULL_STORY_ENABLED !== 'true') {
            return false;
        }

        // only allow for the whitelisted domains or the dev environment
        const hostName = window.location.hostname;
        const whiteListedDomains = [
            'staging.pawshake.com.sg',
            'www.pawshake.co.nz',
            'www.pawshake.ie',
            'www.pawshake.com.au',
            'www.pawshake.co.uk',
        ];

        return whiteListedDomains.includes(hostName);
    };

    private identifyUser(userData: AnalyticsUserData | undefined) {
        if (isClientSide() === false) {
            return;
        }
        if (this.shouldEnableFullStory() && userData) {
            init(
                {
                    orgId: '18JFGF',
                    debug: isDevelopment(),
                },
                () => {
                    if (userData) {
                        identify(userData.userId);
                    }
                }
            );
        }

        window.dataLayer.push({
            userId: userData?.userId ?? null,
            language: userData?.language ?? null,
            countryCode: userData?.countryCode ?? null,
            minProfileRequirementsSet: userData?.hasMinimumProfileRequirementsSet ?? null,
            isSitter: userData?.isSitter ?? null,
        });

        // TODO - deprecated Should be removed once the GTM is fully integrated
        if (isGALoaded()) {
            window.gtag('set', 'user_data', {
                user_id: userData?.userId ?? null,
            });
        }
    }
}
