import styled from 'styled-components';
import Label from '../Label';

const Wrapper = styled.div`
    position: relative;
`;

const ContentWrapper = styled.div`
    position: relative;
    padding: ${(props) => (props.isSelect ? '10px 3rem 10px 10px' : '10px')};
    box-sizing: border-box;
    outline: none;
    width: 100%;
    height: 50px;
    color: #747474;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => (props.focused ? '#00c38a' : '#cecece')};
`;

const Text = styled.div`
    -ms-word-break: break-all;
    word-break: break-all;
`;

const Icon = styled.div`
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    height: 30px;
    display: inline-block;
    width: 1rem;
`;

const FakeInput = ({ onClick, className, label, value, placeholder, isSelect, isOpen }) => {
    const iconSource = isOpen ? 'openContainer' : 'closedContainer';

    return (
        <Wrapper onClick={onClick} className={className}>
            {label && <Label>{label}</Label>}
            <ContentWrapper isSelect={isSelect} focused={isOpen}>
                <Text>{value ? value : placeholder}</Text>
                {isSelect && (
                    <Icon>
                        <img
                            height={'30px'}
                            width={'16px'}
                            src={`https://static1.pawshakecdn.com/global/${iconSource}.svg`}
                            alt={iconSource}
                        />
                    </Icon>
                )}
            </ContentWrapper>
        </Wrapper>
    );
};

export default FakeInput;
